$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "fonts" !default;

$icon-home: "\e900";
$icon-home2: "\e901";
$icon-home3: "\e902";
$icon-office: "\e903";
$icon-newspaper: "\e904";
$icon-pencil: "\e905";
$icon-pencil2: "\e906";
$icon-quill: "\e907";
$icon-pen: "\e908";
$icon-blog: "\e909";
$icon-eyedropper: "\e90a";
$icon-droplet: "\e90b";
$icon-paint-format: "\e90c";
$icon-image: "\e90d";
$icon-images: "\e90e";
$icon-camera: "\e90f";
$icon-headphones: "\e910";
$icon-music: "\e911";
$icon-play: "\e912";
$icon-film: "\e913";
$icon-video-camera: "\e914";
$icon-dice: "\e915";
$icon-pacman: "\e916";
$icon-spades: "\e917";
$icon-clubs: "\e918";
$icon-diamonds: "\e919";
$icon-bullhorn: "\e91a";
$icon-connection: "\e91b";
$icon-podcast: "\e91c";
$icon-feed: "\e91d";
$icon-mic: "\e91e";
$icon-book: "\e91f";
$icon-books: "\e920";
$icon-library: "\e921";
$icon-file-text: "\e922";
$icon-profile: "\e923";
$icon-file-empty: "\e924";
$icon-files-empty: "\e925";
$icon-file-text2: "\e926";
$icon-file-picture: "\e927";
$icon-file-music: "\e928";
$icon-file-play: "\e929";
$icon-file-video: "\e92a";
$icon-file-zip: "\e92b";
$icon-copy: "\e92c";
$icon-paste: "\e92d";
$icon-stack: "\e92e";
$icon-folder: "\e92f";
$icon-folder-open: "\e930";
$icon-folder-plus: "\e931";
$icon-folder-minus: "\e932";
$icon-folder-download: "\e933";
$icon-folder-upload: "\e934";
$icon-price-tag: "\e935";
$icon-price-tags: "\e936";
$icon-barcode: "\e937";
$icon-qrcode: "\e938";
$icon-ticket: "\e939";
$icon-cart: "\e93a";
$icon-coin-dollar: "\e93b";
$icon-coin-euro: "\e93c";
$icon-coin-pound: "\e93d";
$icon-coin-yen: "\e93e";
$icon-credit-card: "\e93f";
$icon-calculator: "\e940";
$icon-lifebuoy: "\e941";
$icon-phone: "\e942";
$icon-phone-hang-up: "\e943";
$icon-address-book: "\e944";
$icon-envelop: "\e945";
$icon-pushpin: "\e946";
$icon-location: "\e947";
$icon-location2: "\e948";
$icon-compass: "\e949";
$icon-compass2: "\e94a";
$icon-map: "\e94b";
$icon-map2: "\e94c";
$icon-history: "\e94d";
$icon-clock: "\e94e";
$icon-clock2: "\e94f";
$icon-alarm: "\e950";
$icon-bell: "\e951";
$icon-stopwatch: "\e952";
$icon-calendar: "\e953";
$icon-printer: "\e954";
$icon-keyboard: "\e955";
$icon-display: "\e956";
$icon-laptop: "\e957";
$icon-mobile: "\e958";
$icon-mobile2: "\e959";
$icon-tablet: "\e95a";
$icon-tv: "\e95b";
$icon-drawer: "\e95c";
$icon-drawer2: "\e95d";
$icon-box-add: "\e95e";
$icon-box-remove: "\e95f";
$icon-download: "\e960";
$icon-upload: "\e961";
$icon-floppy-disk: "\e962";
$icon-drive: "\e963";
$icon-database: "\e964";
$icon-undo: "\e965";
$icon-redo: "\e966";
$icon-undo2: "\e967";
$icon-redo2: "\e968";
$icon-forward: "\e969";
$icon-reply: "\e96a";
$icon-bubble: "\e96b";
$icon-bubbles: "\e96c";
$icon-bubbles2: "\e96d";
$icon-bubble2: "\e96e";
$icon-bubbles3: "\e96f";
$icon-bubbles4: "\e970";
$icon-user: "\e971";
$icon-users: "\e972";
$icon-user-plus: "\e973";
$icon-user-minus: "\e974";
$icon-user-check: "\e975";
$icon-user-tie: "\e976";
$icon-quotes-left: "\e977";
$icon-quotes-right: "\e978";
$icon-hour-glass: "\e979";
$icon-spinner: "\e97a";
$icon-spinner2: "\e97b";
$icon-spinner3: "\e97c";
$icon-spinner4: "\e97d";
$icon-spinner5: "\e97e";
$icon-spinner6: "\e97f";
$icon-spinner7: "\e980";
$icon-spinner8: "\e981";
$icon-spinner9: "\e982";
$icon-spinner10: "\e983";
$icon-spinner11: "\e984";
$icon-binoculars: "\e985";
$icon-search: "\e986";
$icon-zoom-in: "\e987";
$icon-zoom-out: "\e988";
$icon-enlarge: "\e989";
$icon-shrink: "\e98a";
$icon-enlarge2: "\e98b";
$icon-shrink2: "\e98c";
$icon-key: "\e98d";
$icon-key2: "\e98e";
$icon-lock: "\e98f";
$icon-unlocked: "\e990";
$icon-wrench: "\e991";
$icon-equalizer: "\e992";
$icon-equalizer2: "\e993";
$icon-cog: "\e994";
$icon-cogs: "\e995";
$icon-hammer: "\e996";
$icon-magic-wand: "\e997";
$icon-aid-kit: "\e998";
$icon-bug: "\e999";
$icon-pie-chart: "\e99a";
$icon-stats-dots: "\e99b";
$icon-stats-bars: "\e99c";
$icon-stats-bars2: "\e99d";
$icon-trophy: "\e99e";
$icon-gift: "\e99f";
$icon-glass: "\e9a0";
$icon-glass2: "\e9a1";
$icon-mug: "\e9a2";
$icon-spoon-knife: "\e9a3";
$icon-leaf: "\e9a4";
$icon-rocket: "\e9a5";
$icon-meter: "\e9a6";
$icon-meter2: "\e9a7";
$icon-hammer2: "\e9a8";
$icon-fire: "\e9a9";
$icon-lab: "\e9aa";
$icon-magnet: "\e9ab";
$icon-bin: "\e9ac";
$icon-bin2: "\e9ad";
$icon-briefcase: "\e9ae";
$icon-airplane: "\e9af";
$icon-truck: "\e9b0";
$icon-road: "\e9b1";
$icon-accessibility: "\e9b2";
$icon-target: "\e9b3";
$icon-shield: "\e9b4";
$icon-power: "\e9b5";
$icon-switch: "\e9b6";
$icon-power-cord: "\e9b7";
$icon-clipboard: "\e9b8";
$icon-list-numbered: "\e9b9";
$icon-list: "\e9ba";
$icon-list2: "\e9bb";
$icon-tree: "\e9bc";
$icon-menu: "\e9bd";
$icon-menu2: "\e9be";
$icon-menu3: "\e9bf";
$icon-menu4: "\e9c0";
$icon-cloud: "\e9c1";
$icon-cloud-download: "\e9c2";
$icon-cloud-upload: "\e9c3";
$icon-cloud-check: "\e9c4";
$icon-download2: "\e9c5";
$icon-upload2: "\e9c6";
$icon-download3: "\e9c7";
$icon-upload3: "\e9c8";
$icon-sphere: "\e9c9";
$icon-earth: "\e9ca";
$icon-link: "\e9cb";
$icon-flag: "\e9cc";
$icon-attachment: "\e9cd";
$icon-eye: "\e9ce";
$icon-eye-plus: "\e9cf";
$icon-eye-minus: "\e9d0";
$icon-eye-blocked: "\e9d1";
$icon-bookmark: "\e9d2";
$icon-bookmarks: "\e9d3";
$icon-sun: "\e9d4";
$icon-contrast: "\e9d5";
$icon-brightness-contrast: "\e9d6";
$icon-star-empty: "\e9d7";
$icon-star-half: "\e9d8";
$icon-star-full: "\e9d9";
$icon-heart: "\e9da";
$icon-heart-broken: "\e9db";
$icon-man: "\e9dc";
$icon-woman: "\e9dd";
$icon-man-woman: "\e9de";
$icon-happy: "\e9df";
$icon-happy2: "\e9e0";
$icon-smile: "\e9e1";
$icon-smile2: "\e9e2";
$icon-tongue: "\e9e3";
$icon-tongue2: "\e9e4";
$icon-sad: "\e9e5";
$icon-sad2: "\e9e6";
$icon-wink: "\e9e7";
$icon-wink2: "\e9e8";
$icon-grin: "\e9e9";
$icon-grin2: "\e9ea";
$icon-cool: "\e9eb";
$icon-cool2: "\e9ec";
$icon-angry: "\e9ed";
$icon-angry2: "\e9ee";
$icon-evil: "\e9ef";
$icon-evil2: "\e9f0";
$icon-shocked: "\e9f1";
$icon-shocked2: "\e9f2";
$icon-baffled: "\e9f3";
$icon-baffled2: "\e9f4";
$icon-confused: "\e9f5";
$icon-confused2: "\e9f6";
$icon-neutral: "\e9f7";
$icon-neutral2: "\e9f8";
$icon-hipster: "\e9f9";
$icon-hipster2: "\e9fa";
$icon-wondering: "\e9fb";
$icon-wondering2: "\e9fc";
$icon-sleepy: "\e9fd";
$icon-sleepy2: "\e9fe";
$icon-frustrated: "\e9ff";
$icon-frustrated2: "\ea00";
$icon-crying: "\ea01";
$icon-crying2: "\ea02";
$icon-point-up: "\ea03";
$icon-point-right: "\ea04";
$icon-point-down: "\ea05";
$icon-point-left: "\ea06";
$icon-warning: "\ea07";
$icon-notification: "\ea08";
$icon-question: "\ea09";
$icon-plus: "\ea0a";
$icon-minus: "\ea0b";
$icon-info: "\ea0c";
$icon-cancel-circle: "\ea0d";
$icon-blocked: "\ea0e";
$icon-cross: "\ea0f";
$icon-checkmark: "\ea10";
$icon-checkmark2: "\ea11";
$icon-spell-check: "\ea12";
$icon-enter: "\ea13";
$icon-exit: "\ea14";
$icon-play2: "\ea15";
$icon-pause: "\ea16";
$icon-stop: "\ea17";
$icon-previous: "\ea18";
$icon-next: "\ea19";
$icon-backward: "\ea1a";
$icon-forward2: "\ea1b";
$icon-play3: "\ea1c";
$icon-pause2: "\ea1d";
$icon-stop2: "\ea1e";
$icon-backward2: "\ea1f";
$icon-forward3: "\ea20";
$icon-first: "\ea21";
$icon-last: "\ea22";
$icon-previous2: "\ea23";
$icon-next2: "\ea24";
$icon-eject: "\ea25";
$icon-volume-high: "\ea26";
$icon-volume-medium: "\ea27";
$icon-volume-low: "\ea28";
$icon-volume-mute: "\ea29";
$icon-volume-mute2: "\ea2a";
$icon-volume-increase: "\ea2b";
$icon-volume-decrease: "\ea2c";
$icon-loop: "\ea2d";
$icon-loop2: "\ea2e";
$icon-infinite: "\ea2f";
$icon-shuffle: "\ea30";
$icon-arrow-up-left: "\ea31";
$icon-arrow-up: "\ea32";
$icon-arrow-up-right: "\ea33";
$icon-arrow-right: "\ea34";
$icon-arrow-down-right: "\ea35";
$icon-arrow-down: "\ea36";
$icon-arrow-down-left: "\ea37";
$icon-arrow-left: "\ea38";
$icon-arrow-up-left2: "\ea39";
$icon-arrow-up2: "\ea3a";
$icon-arrow-up-right2: "\ea3b";
$icon-arrow-right2: "\ea3c";
$icon-arrow-down-right2: "\ea3d";
$icon-arrow-down2: "\ea3e";
$icon-arrow-down-left2: "\ea3f";
$icon-arrow-left2: "\ea40";
$icon-circle-up: "\ea41";
$icon-circle-right: "\ea42";
$icon-circle-down: "\ea43";
$icon-circle-left: "\ea44";
$icon-tab: "\ea45";
$icon-move-up: "\ea46";
$icon-move-down: "\ea47";
$icon-sort-alpha-asc: "\ea48";
$icon-sort-alpha-desc: "\ea49";
$icon-sort-numeric-asc: "\ea4a";
$icon-sort-numberic-desc: "\ea4b";
$icon-sort-amount-asc: "\ea4c";
$icon-sort-amount-desc: "\ea4d";
$icon-command: "\ea4e";
$icon-shift: "\ea4f";
$icon-ctrl: "\ea50";
$icon-opt: "\ea51";
$icon-checkbox-checked: "\ea52";
$icon-checkbox-unchecked: "\ea53";
$icon-radio-checked: "\ea54";
$icon-radio-checked2: "\ea55";
$icon-radio-unchecked: "\ea56";
$icon-crop: "\ea57";
$icon-make-group: "\ea58";
$icon-ungroup: "\ea59";
$icon-scissors: "\ea5a";
$icon-filter: "\ea5b";
$icon-font: "\ea5c";
$icon-ligature: "\ea5d";
$icon-ligature2: "\ea5e";
$icon-text-height: "\ea5f";
$icon-text-width: "\ea60";
$icon-font-size: "\ea61";
$icon-bold: "\ea62";
$icon-underline: "\ea63";
$icon-italic: "\ea64";
$icon-strikethrough: "\ea65";
$icon-omega: "\ea66";
$icon-sigma: "\ea67";
$icon-page-break: "\ea68";
$icon-superscript: "\ea69";
$icon-subscript: "\ea6a";
$icon-superscript2: "\ea6b";
$icon-subscript2: "\ea6c";
$icon-text-color: "\ea6d";
$icon-pagebreak: "\ea6e";
$icon-clear-formatting: "\ea6f";
$icon-table: "\ea70";
$icon-table2: "\ea71";
$icon-insert-template: "\ea72";
$icon-pilcrow: "\ea73";
$icon-ltr: "\ea74";
$icon-rtl: "\ea75";
$icon-section: "\ea76";
$icon-paragraph-left: "\ea77";
$icon-paragraph-center: "\ea78";
$icon-paragraph-right: "\ea79";
$icon-paragraph-justify: "\ea7a";
$icon-indent-increase: "\ea7b";
$icon-indent-decrease: "\ea7c";
$icon-share: "\ea7d";
$icon-new-tab: "\ea7e";
$icon-embed: "\ea7f";
$icon-embed2: "\ea80";
$icon-terminal: "\ea81";
$icon-share2: "\ea82";
$icon-mail: "\ea83";
$icon-mail2: "\ea84";
$icon-mail3: "\ea85";
$icon-mail4: "\ea86";
$icon-amazon: "\ea87";
$icon-google: "\ea88";
$icon-google2: "\ea89";
$icon-google3: "\ea8a";
$icon-google-plus: "\ea8b";
$icon-google-plus2: "\ea8c";
$icon-google-plus3: "\ea8d";
$icon-hangouts: "\ea8e";
$icon-google-drive: "\ea8f";
$icon-facebook: "\ea90";
$icon-facebook2: "\ea91";
$icon-instagram: "\ea92";
$icon-whatsapp: "\ea93";
$icon-spotify: "\ea94";
$icon-telegram: "\ea95";
$icon-twitter: "\ea96";
$icon-vine: "\ea97";
$icon-vk: "\ea98";
$icon-renren: "\ea99";
$icon-sina-weibo: "\ea9a";
$icon-rss: "\ea9b";
$icon-rss2: "\ea9c";
$icon-youtube: "\ea9d";
$icon-youtube2: "\ea9e";
$icon-twitch: "\ea9f";
$icon-vimeo: "\eaa0";
$icon-vimeo2: "\eaa1";
$icon-lanyrd: "\eaa2";
$icon-flickr: "\eaa3";
$icon-flickr2: "\eaa4";
$icon-flickr3: "\eaa5";
$icon-flickr4: "\eaa6";
$icon-dribbble: "\eaa7";
$icon-behance: "\eaa8";
$icon-behance2: "\eaa9";
$icon-deviantart: "\eaaa";
$icon-500px: "\eaab";
$icon-steam: "\eaac";
$icon-steam2: "\eaad";
$icon-dropbox: "\eaae";
$icon-onedrive: "\eaaf";
$icon-github: "\eab0";
$icon-npm: "\eab1";
$icon-basecamp: "\eab2";
$icon-trello: "\eab3";
$icon-wordpress: "\eab4";
$icon-joomla: "\eab5";
$icon-ello: "\eab6";
$icon-blogger: "\eab7";
$icon-blogger2: "\eab8";
$icon-tumblr: "\eab9";
$icon-tumblr2: "\eaba";
$icon-yahoo: "\eabb";
$icon-yahoo2: "\eabc";
$icon-tux: "\eabd";
$icon-appleinc: "\eabe";
$icon-finder: "\eabf";
$icon-android: "\eac0";
$icon-windows: "\eac1";
$icon-windows8: "\eac2";
$icon-soundcloud: "\eac3";
$icon-soundcloud2: "\eac4";
$icon-skype: "\eac5";
$icon-reddit: "\eac6";
$icon-hackernews: "\eac7";
$icon-wikipedia: "\eac8";
$icon-linkedin: "\eac9";
$icon-linkedin2: "\eaca";
$icon-lastfm: "\eacb";
$icon-lastfm2: "\eacc";
$icon-delicious: "\eacd";
$icon-stumbleupon: "\eace";
$icon-stumbleupon2: "\eacf";
$icon-stackoverflow: "\ead0";
$icon-pinterest: "\ead1";
$icon-pinterest2: "\ead2";
$icon-xing: "\ead3";
$icon-xing2: "\ead4";
$icon-flattr: "\ead5";
$icon-foursquare: "\ead6";
$icon-yelp: "\ead7";
$icon-paypal: "\ead8";
$icon-chrome: "\ead9";
$icon-firefox: "\eada";
$icon-IE: "\eadb";
$icon-edge: "\eadc";
$icon-safari: "\eadd";
$icon-opera: "\eade";
$icon-file-pdf: "\eadf";
$icon-file-openoffice: "\eae0";
$icon-file-word: "\eae1";
$icon-file-excel: "\eae2";
$icon-libreoffice: "\eae3";
$icon-html-five: "\eae4";
$icon-html-five2: "\eae5";
$icon-css3: "\eae6";
$icon-git: "\eae7";
$icon-codepen: "\eae8";
$icon-svg: "\eae9";
$icon-IcoMoon: "\eaea";

