@tailwind base;

@layer base {
  @import "typo/style.scss";
}

@import "theme";

@tailwind components;
@tailwind utilities;
