h2 {
  @apply text-4xl;
}

h3 {
  @screen md {
    @apply text-2xl;
  }
}

h4 {
  @apply text-2xl;
}

.form-radio,
.form-checkbox {
  @apply mr-2;
}

.stretched-link {
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    content: "";
  }
}
